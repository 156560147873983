import React from "react";

interface IProps {
  subTitle: string;
  title: string;
  message?: string;
}
const SectionHeader = (props: IProps) => {
  return (
    <div className="section-header uk-text-center">
      <h6 className="section-header__sub-title">{props.subTitle}</h6>
      <h4 className="section-header__title">{props.title}</h4>
      {props.message && (
        <p className="section-header__message">{props.message}</p>
      )}
    </div>
  );
};

export default SectionHeader;
