import Store from "./Store";
import AppStore from "./AppStore";
import { runInAction } from "mobx";
import MeasureAuditDepartment, {
  IMeasureAuditDepartment,
} from "../models/MeasureAuditDepartment";
import { sortAlphabetically } from "../../logged-in/shared/utils/utils";

export default class DepartmentMeasureAuditStore extends Store<
  IMeasureAuditDepartment,
  MeasureAuditDepartment
> {
  items = new Map<string, MeasureAuditDepartment>();

  constructor(store: AppStore) {
    super(store);
    this.store = store;
  }

  load(items: IMeasureAuditDepartment[] = []) {
    runInAction(() => {
      items.forEach((item) =>
        this.items.set(item.id, new MeasureAuditDepartment(this.store, item))
      );
    });
  }

  // get all measures by uid
  getByDepartment(department: string) {
    return this.all.filter((item) => item.asJson.department === department).sort((a, b) =>
      sortAlphabetically(a.asJson.description, b.asJson.description)
    );
  }
}
