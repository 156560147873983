// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { initializeFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB00hqtzHHOH_3D3SMj9KhAGrG-gn4kmrs",
  authDomain: "lots-pms.firebaseapp.com",
  projectId: "lots-pms",
  storageBucket: "lots-pms.appspot.com",
  messagingSenderId: "507817531923",
  appId: "1:507817531923:web:f6813ccf3db341eaa1fde6",
  measurementId: "G-27F3J0Q8S7",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const appAuthWorker = initializeApp(firebaseConfig);

export const analytics = getAnalytics(app);
export const db = initializeFirestore(app, { experimentalForceLongPolling: true, });
export const auth = getAuth(app);
export const authWorker = getAuth(appAuthWorker);
export const storage = getStorage(app);
export const functions = getFunctions(app);

// work-shop pms

// apiKey: "AIzaSyBoo1MsZAa7C6N7g4F_kd20_9F47EWsnxU",
// authDomain: "pms-workshop.firebaseapp.com",
// projectId: "pms-workshop",
// storageBucket: "pms-workshop.appspot.com",
// messagingSenderId: "1003334609013",
// appId: "1:1003334609013:web:fe72f5904e63c2acf4f03c",
// measurementId: "G-HCEE2B52PK"


//lots pms

// apiKey: "AIzaSyB00hqtzHHOH_3D3SMj9KhAGrG-gn4kmrs",
// authDomain: "lots-pms.firebaseapp.com",
// projectId: "lots-pms",
// storageBucket: "lots-pms.appspot.com",
// messagingSenderId: "507817531923",
// appId: "1:507817531923:web:f6813ccf3db341eaa1fde6",
// measurementId: "G-27F3J0Q8S7",


//project demo
// apiKey: "AIzaSyCBVjcPp9XqMGsCobt6Y1jqMDmavQ0bvvI",
// authDomain: "writers-b5fa8.firebaseapp.com",
// projectId: "writers-b5fa8",
// storageBucket: "writers-b5fa8.appspot.com",
// messagingSenderId: "966077734342",
// appId: "1:966077734342:web:90ef89b8c3421d28e966c7",
// measurementId: "G-GM3HVQM9JL"