import { lazy, Suspense } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { USER_ROLES } from "./shared/functions/CONSTANTS";
import { AppContext, useAppContext } from "./shared/functions/Context";
import useNetwork from "./shared/hooks/useNetwork";
import AppApi from "./shared/apis/AppApi";
import AppStore from "./shared/stores/AppStore";
import UiStore from "./shared/stores/UiStore";
import LandingPage from "./logged-out/landing-page/landing/LandingPage";
import Loading from "./shared/components/loading/Loading";
import SnackbarManager from "./shared/components/snackbar/SnackbarManager";
import PrivateRoute from "./shared/functions/PrivateRoute";
import "./App.scss";

const IndividualScorecard = lazy(() => import("./logged-in/execution-scorecard/IndividualScorecard"));
const People = lazy(() => import("./logged-in/execution-team/People"));
const Dashboard = lazy(() => import("./logged-in/dashboard/Dashboard"));
const AdminSettings = lazy(() => import("./logged-in/admin-settings/AdminSettings"));
const Reports = lazy(() => import("./logged-in/reports/Reports"));
const EmployeeScorecard = lazy(() => import("./logged-in/execution-supervision/EmployeeScorecard"));
const PeopleView = lazy(() => import("./logged-in/execution-team-view/PeopleView"));
const EmployeeScorecardView = lazy(() => import("./logged-in/execution-supervision-view/EmployeeScorecardView"));
const CompanyScorecards = lazy(() => import("./logged-in/company-scorecards/CompanyScorecards"));
const CompanyScorecard = lazy(() => import("./logged-in/company-scorecard/CompanyScorecard"));
const DepartmentScorecards = lazy(() => import("./logged-in/department-scorecards/DepartmentScorecards"));
const DepartmentScorecard = lazy(() => import("./logged-in/department-scorecard/DepartmentScorecard"));
const CompanyScorecardObjective = lazy(() => import("./logged-in/company-scorecard-objective/CompanyScorecardObjective"));
const DepartmentScorecardObjective = lazy(() => import("./logged-in/department-scorecard-objective/DepartmentScorecardObjective"));
const PerformanceReviews = lazy(() => import("./logged-in/performance-reviews/PerformanceReviews"));
const Drive = lazy(() => import("./logged-in/drive/Drive"));
const IndividualScorecardDraftObjective = lazy(() => import("./logged-in/execution-scorecard-objective-draft/IndividualScorecardDraftObjective"));
const StrategyThemes = lazy(() => import("./logged-in/strategy-themes/StrategyThemes"));
const DepartmentScorecardReviews = lazy(() => import("./logged-in/strategy-department-scorecard-reviews/DepartmentScorecardReviews"));
const DepartmentScorecardReviewView = lazy(() => import("./logged-in/strategy-department-scorecard-reviews-view/DepartmentScorecardReviewView"));
const CompanyScorecardReviews = lazy(() => import("./logged-in/company-scorecard-reviews/CompanyScorecardReviews"));
const CompanyScorecardReviewView = lazy(() => import("./logged-in/company-scorecard-reviews-view/CompanyScorecardReviewView"));
const StrategyMapPage = lazy(() => import("./logged-in/execution-scorecard-strategy-map/StrategyMapPage"));
const ProjectView = lazy(() => import("./logged-in/project-management/ProjectView"));
const PortfolioPage = lazy(() => import("./logged-in/project-management/PortfolioPage"));
const PortfolioProjects = lazy(() => import("./logged-in/project-management/PortfolioProjects"));
const Statistics = lazy(() => import("./logged-in/project-management/Statistics"));
const Tasks = lazy(() => import("./logged-in/project-management/Tasks"));
const UserProjects = lazy(() => import("./logged-in/project-management/UsersProjects"));
const CheckIn = lazy(() => import("./logged-in/check-in-year/CheckIn"));
const CheckInYearView = lazy(() => import("./logged-in/check-in-year-view/CheckInYearView"));
const CheckInMonthView = lazy(() => import("./logged-in/check-in-month-view/CheckInMonthView"));
const CheckInMonthUserView = lazy(() => import("./logged-in/check-in-month-user-view/CheckInMonthUserView"));
const LoggedIn = lazy(() => import("./logged-in/LoggedIn"));

const PrivateLoggedIn = () => (
  <PrivateRoute>
    <Suspense fallback={<Loading fullHeight={true} />}>
      <LoggedIn />
    </Suspense>
  </PrivateRoute>
);
const DEV_MODE_ROUTES = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="c" element={<PrivateLoggedIn />}>
          {/* OVERVIEW */}
          <Route path="home/dashboard" element={<Dashboard />} />
          <Route path="home/strategy-map" element={<StrategyMapPage />} />

          {/* STRATEGY */}
          <Route path="strategy/themes" element={<StrategyThemes />} />
          <Route path="strategy/company" element={<CompanyScorecards />} />
          <Route path="strategy/company/:fyid" element={<CompanyScorecard />} />
          <Route
            path="strategy/company/:fyid/:objectiveId"
            element={<CompanyScorecardObjective />}
          />
          <Route
            path="strategy/company-review"
            element={<CompanyScorecardReviews />}
          />
          <Route
            path="strategy/company-review/:fyid"
            element={<CompanyScorecardReviewView />}
          />

          <Route
            path="strategy/department"
            element={<DepartmentScorecards />}
          />
          <Route
            path="strategy/department/:fyid/:departmentId"
            element={<DepartmentScorecard />}
          />
          <Route
            path="strategy/department/:fyid/:departmentId/:objectiveId"
            element={<DepartmentScorecardObjective />}
          />

          <Route
            path="strategy/department-review"
            element={<DepartmentScorecardReviews />}
          />
          <Route
            path="strategy/department-review/:fyid/:departmentId"
            element={<DepartmentScorecardReviewView />}
          />

          {/* SCORECARDS */}
          {/* Executive & MD don't have personal/individual scorecard */}
          <Route path="scorecards/my" element={<IndividualScorecard />} />
          <Route
            path="scorecards/my/:id"
            element={<IndividualScorecardDraftObjective />}
          />
          <Route
            path="scorecards/supervision"
            element={<EmployeeScorecard />}
          />
          <Route
            path="scorecards/supervision/:uid"
            element={<EmployeeScorecardView />}
          />
          <Route path="scorecards/people" element={<People />} />
          <Route path="scorecards/people/:uid" element={<PeopleView />} />
          <Route path="scorecards/reviews" element={<PerformanceReviews />} />

          {/* PORTFOLIO OF EVIDENCE */}
          <Route path="drive" element={<Drive />} />
          <Route path="drive/:id" element={<Drive />} />

          {/* project management */}
          <Route path="projects" element={<UserProjects />} />
          <Route path="projects/:id" element={<PortfolioProjects />} />
          <Route path="project/:projectId" element={<ProjectView />} />
          <Route path="portfolio" element={<PortfolioPage />} />
          <Route path="tasks" element={<Tasks />} />
          <Route path="checkin" element={<CheckIn />} />
          <Route path="checkin/:yearId" element={<CheckInYearView />} />
          <Route path="checkin/:yearId/:monthId" element={<CheckInMonthView />} />
          <Route path="checkin/:yearId/:monthId/:uid" element={<CheckInMonthUserView />} />

          <Route path="statistics" element={<Statistics />} />

          {/* REPORTS */}
          <Route path="reports/kpis" element={<Reports />} />

          {/* ADMIN */}
          <Route path="admin/settings" element={<AdminSettings />} />
          <Route path="*" element={<Navigate to="home/dashboard" />} />
        </Route>

        <Route path="/" element={<LandingPage />} />
        <Route path="/*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
};
const DIRECTOR_USER_ROUTES = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="c" element={<PrivateLoggedIn />}>
          {/* OVERVIEW */}
          <Route path="home/dashboard" element={<Dashboard />} />
          <Route path="home/strategy-map" element={<StrategyMapPage />} />

          {/* STRATEGY */}
          <Route path="strategy/company" element={<CompanyScorecards />} />
          <Route path="strategy/company/:fyid" element={<CompanyScorecard />} />
          <Route
            path="strategy/company/:fyid/:objectiveId"
            element={<CompanyScorecardObjective />}
          />
          <Route
            path="strategy/company-review"
            element={<CompanyScorecardReviews />}
          />
          <Route
            path="strategy/company-review/:fyid"
            element={<CompanyScorecardReviewView />}
          />

          <Route path="*" element={<Navigate to="home/dashboard" />} />
        </Route>

        <Route path="/" element={<LandingPage />} />
        <Route path="/*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
};
const MD_USER_ROUTES = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="c" element={<PrivateLoggedIn />}>
          {/* OVERVIEW */}
          <Route path="home/dashboard" element={<Dashboard />} />
          <Route path="home/strategy-map" element={<StrategyMapPage />} />

          {/* STRATEGY */}
          <Route path="strategy/company" element={<CompanyScorecards />} />
          <Route path="strategy/company/:fyid" element={<CompanyScorecard />} />
          <Route
            path="strategy/company/:fyid/:objectiveId"
            element={<CompanyScorecardObjective />}
          />
          <Route
            path="strategy/department"
            element={<DepartmentScorecards />}
          />
          <Route
            path="strategy/department/:fyid/:departmentId"
            element={<DepartmentScorecard />}
          />
          <Route
            path="strategy/department/:fyid/:departmentId/:objectiveId"
            element={<DepartmentScorecardObjective />}
          />

          <Route
            path="strategy/department-review"
            element={<DepartmentScorecardReviews />}
          />
          <Route
            path="strategy/department-review/:fyid/:departmentId"
            element={<DepartmentScorecardReviewView />}
          />

          {/* SCORECARDS */}
          {/* Executive & MD don't have personal/individual scorecard */}
          <Route path="scorecards/my" element={<IndividualScorecard />} />
          <Route
            path="scorecards/my/:id"
            element={<IndividualScorecardDraftObjective />}
          />
          <Route
            path="scorecards/supervision"
            element={<EmployeeScorecard />}
          />
          <Route
            path="scorecards/supervision/:uid"
            element={<EmployeeScorecardView />}
          />
          <Route path="scorecards/people" element={<People />} />
          <Route path="scorecards/people/:uid" element={<PeopleView />} />
          <Route path="scorecards/reviews" element={<PerformanceReviews />} />

          {/* PORTFOLIO OF EVIDENCE */}
          <Route path="drive" element={<Drive />} />
          <Route path="drive/:id" element={<Drive />} />

          {/* project management */}
          <Route path="projects" element={<UserProjects />} />
          <Route path="projects/:id" element={<PortfolioProjects />} />
          <Route path="project/:projectId" element={<ProjectView />} />
          <Route path="portfolio" element={<PortfolioPage />} />
          <Route path="tasks" element={<Tasks />} />
          <Route path="checkin" element={<CheckIn />} />
          <Route path="checkin/:yearId" element={<CheckInYearView />} />
          <Route path="checkin/:yearId/:monthId" element={<CheckInMonthView />} />
          <Route path="checkin/:yearId/:monthId/:uid" element={<CheckInMonthUserView />} />
          <Route path="statistics" element={<Statistics />} />

          {/* REPORTS */}
          <Route path="reports/kpis" element={<Reports />} />

          <Route path="*" element={<Navigate to="home/dashboard" />} />
        </Route>

        <Route path="/" element={<LandingPage />} />
        <Route path="/*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
};
const SUPER_USER_ROUTES = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="c" element={<PrivateLoggedIn />}>
          {/* OVERVIEW */}
          <Route path="home/dashboard" element={<Dashboard />} />
          <Route path="home/strategy-map" element={<StrategyMapPage />} />

          {/* STRATEGY */}
          <Route path="strategy/company" element={<CompanyScorecards />} />
          <Route path="strategy/company/:fyid" element={<CompanyScorecard />} />
          <Route
            path="strategy/company/:fyid/:objectiveId"
            element={<CompanyScorecardObjective />}
          />
          <Route
            path="strategy/department"
            element={<DepartmentScorecards />}
          />
          <Route
            path="strategy/department/:fyid/:departmentId"
            element={<DepartmentScorecard />}
          />
          <Route
            path="strategy/department/:fyid/:departmentId/:objectiveId"
            element={<DepartmentScorecardObjective />}
          />
          <Route
            path="strategy/department-review"
            element={<DepartmentScorecardReviews />}
          />
          <Route
            path="strategy/department-review/:fyid/:departmentId"
            element={<DepartmentScorecardReviewView />}
          />

          {/* SCORECARDS */}
          {/* Executive & MD don't have personal/individual scorecard */}
          <Route path="scorecards/my" element={<IndividualScorecard />} />
          <Route
            path="scorecards/my/:id"
            element={<IndividualScorecardDraftObjective />}
          />
          <Route
            path="scorecards/supervision"
            element={<EmployeeScorecard />}
          />
          <Route
            path="scorecards/supervision/:uid"
            element={<EmployeeScorecardView />}
          />
          <Route path="scorecards/people" element={<People />} />
          <Route path="scorecards/people/:uid" element={<PeopleView />} />
          <Route path="scorecards/reviews" element={<PerformanceReviews />} />

          {/* PORTFOLIO OF EVIDENCE */}
          <Route path="drive" element={<Drive />} />
          <Route path="drive/:id" element={<Drive />} />

          {/* project management */}
          <Route path="projects" element={<UserProjects />} />
          <Route path="projects/:id" element={<PortfolioProjects />} />
          <Route path="project/:projectId" element={<ProjectView />} />
          <Route path="portfolio" element={<PortfolioPage />} />
          <Route path="tasks" element={<Tasks />} />
          <Route path="checkin" element={<CheckIn />} />
          <Route path="checkin/:yearId" element={<CheckInYearView />} />
          <Route path="checkin/:yearId/:monthId" element={<CheckInMonthView />} />
          <Route path="checkin/:yearId/:monthId/:uid" element={<CheckInMonthUserView />} />
          <Route path="statistics" element={<Statistics />} />

          {/* REPORTS */}
          <Route path="reports/kpis" element={<Reports />} />

          <Route path="*" element={<Navigate to="home/dashboard" />} />
        </Route>

        <Route path="/" element={<LandingPage />} />
        <Route path="/*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
};
const EXECUTIVE_USER_ROUTES = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="c" element={<PrivateLoggedIn />}>
          {/* OVERVIEW */}
          <Route path="home/dashboard" element={<Dashboard />} />
          <Route path="home/strategy-map" element={<StrategyMapPage />} />

          {/* STRATEGY */}
          <Route path="strategy/company" element={<CompanyScorecards />} />
          <Route path="strategy/company/:fyid" element={<CompanyScorecard />} />
          <Route
            path="strategy/company/:fyid/:objectiveId"
            element={<CompanyScorecardObjective />}
          />
          <Route
            path="strategy/department"
            element={<DepartmentScorecards />}
          />
          <Route
            path="strategy/department/:fyid/:departmentId"
            element={<DepartmentScorecard />}
          />
          <Route
            path="strategy/department/:fyid/:departmentId/:objectiveId"
            element={<DepartmentScorecardObjective />}
          />

          {/* SCORECARDS */}
          <Route
            path="scorecards/supervision"
            element={<EmployeeScorecard />}
          />
          <Route
            path="scorecards/supervision/:uid"
            element={<EmployeeScorecardView />}
          />
          <Route path="scorecards/people" element={<People />} />
          <Route path="scorecards/people/:uid" element={<PeopleView />} />
          <Route path="scorecards/reviews" element={<PerformanceReviews />} />

          {/* PORTFOLIO OF EVIDENCE */}
          <Route path="drive" element={<Drive />} />
          <Route path="drive/:id" element={<Drive />} />

          {/* project management */}
          <Route path="projects" element={<UserProjects />} />
          <Route path="projects/:id" element={<PortfolioProjects />} />
          <Route path="project/:projectId" element={<ProjectView />} />
          <Route path="portfolio" element={<PortfolioPage />} />
          <Route path="tasks" element={<Tasks />} />
          <Route path="checkin" element={<CheckIn />} />
          <Route path="checkin/:yearId" element={<CheckInYearView />} />
          <Route path="checkin/:yearId/:monthId" element={<CheckInMonthView />} />
          <Route path="checkin/:yearId/:monthId/:uid" element={<CheckInMonthUserView />} />
          <Route path="statistics" element={<Statistics />} />

          {/* REPORTS */}
          <Route path="reports/kpis" element={<Reports />} />

          <Route path="*" element={<Navigate to="home/dashboard" />} />
        </Route>

        <Route path="/" element={<LandingPage />} />
        <Route path="/*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
};
const ADMIN_USER_ROUTES = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="c" element={<PrivateLoggedIn />}>
          {/* OVERVIEW */}
          <Route path="home/dashboard" element={<Dashboard />} />
          <Route path="home/strategy-map" element={<StrategyMapPage />} />

          {/* STRATEGY */}
          <Route path="strategy/company" element={<CompanyScorecards />} />
          <Route path="strategy/company/:fyid" element={<CompanyScorecard />} />
          <Route
            path="strategy/company/:fyid/:objectiveId"
            element={<CompanyScorecardObjective />}
          />
          <Route
            path="strategy/department"
            element={<DepartmentScorecards />}
          />
          <Route
            path="strategy/department/:fyid/:departmentId"
            element={<DepartmentScorecard />}
          />
          <Route
            path="strategy/department/:fyid/:departmentId/:objectiveId"
            element={<DepartmentScorecardObjective />}
          />

          {/* SCORECARDS */}
          <Route path="scorecards/my" element={<IndividualScorecard />} />
          <Route
            path="scorecards/my/:id"
            element={<IndividualScorecardDraftObjective />}
          />
          <Route
            path="scorecards/supervision"
            element={<EmployeeScorecard />}
          />
          <Route
            path="scorecards/supervision/:uid"
            element={<EmployeeScorecardView />}
          />
          <Route path="scorecards/people" element={<People />} />
          <Route path="scorecards/people/:uid" element={<PeopleView />} />

          {/* PORTFOLIO OF EVIDENCE */}
          <Route path="drive" element={<Drive />} />
          <Route path="drive/:id" element={<Drive />} />

          {/* project management */}
          <Route path="projects" element={<UserProjects />} />
          <Route path="projects/:id" element={<PortfolioProjects />} />
          <Route path="project/:projectId" element={<ProjectView />} />
          <Route path="portfolio" element={<PortfolioPage />} />
          <Route path="tasks" element={<Tasks />} />
          <Route path="checkin" element={<CheckIn />} />
          <Route path="checkin/:yearId" element={<CheckInYearView />} />
          <Route path="checkin/:yearId/:monthId" element={<CheckInMonthView />} />
          <Route path="checkin/:yearId/:monthId/:uid" element={<CheckInMonthUserView />} />
          <Route path="statistics" element={<Statistics />} />

          {/* ADMIN */}
          <Route path="admin/settings" element={<AdminSettings />} />
          <Route path="*" element={<Navigate to="home/dashboard" />} />
        </Route>

        <Route path="/" element={<LandingPage />} />
        <Route path="/*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
};
const MANAGER_USER_ROUTES = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="c" element={<PrivateLoggedIn />}>
          {/* OVERVIEW */}
          <Route path="home/dashboard" element={<Dashboard />} />
          <Route path="home/strategy-map" element={<StrategyMapPage />} />

          {/* STRATEGY */}
          <Route path="strategy/company" element={<CompanyScorecards />} />
          <Route path="strategy/company/:fyid" element={<CompanyScorecard />} />
          <Route
            path="strategy/company/:fyid/:objectiveId"
            element={<CompanyScorecardObjective />}
          />

          <Route
            path="strategy/department"
            element={<DepartmentScorecards />}
          />
          <Route
            path="strategy/department/:fyid/:departmentId"
            element={<DepartmentScorecard />}
          />

          {/* SCORECARDS */}
          {/* Executive & MD don't have personal/individual scorecard */}
          <Route path="scorecards/my" element={<IndividualScorecard />} />
          <Route
            path="scorecards/my/:id"
            element={<IndividualScorecardDraftObjective />}
          />
          <Route
            path="scorecards/supervision"
            element={<EmployeeScorecard />}
          />
          <Route
            path="scorecards/supervision/:uid"
            element={<EmployeeScorecardView />}
          />
          <Route path="scorecards/people" element={<People />} />
          <Route path="scorecards/people/:uid" element={<PeopleView />} />
          <Route path="scorecards/reviews" element={<PerformanceReviews />} />

          {/* PORTFOLIO OF EVIDENCE */}
          <Route path="drive" element={<Drive />} />
          <Route path="drive/:id" element={<Drive />} />

          {/* project management */}
          <Route path="projects" element={<UserProjects />} />
          <Route path="projects/:id" element={<PortfolioProjects />} />
          <Route path="project/:projectId" element={<ProjectView />} />
          <Route path="portfolio" element={<PortfolioPage />} />
          <Route path="tasks" element={<Tasks />} />
          <Route path="checkin" element={<CheckIn />} />
          <Route path="checkin/:yearId" element={<CheckInYearView />} />
          <Route path="checkin/:yearId/:monthId" element={<CheckInMonthView />} />
          <Route path="checkin/:yearId/:monthId/:uid" element={<CheckInMonthUserView />} />
          <Route path="statistics" element={<Statistics />} />

          <Route path="*" element={<Navigate to="home/dashboard" />} />
        </Route>

        <Route path="/" element={<LandingPage />} />
        <Route path="/*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
};
const SUPERVISOR_USER_ROUTES = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="c" element={<PrivateLoggedIn />}>
          {/* OVERVIEW */}
          <Route path="home/dashboard" element={<Dashboard />} />
          <Route path="home/strategy-map" element={<StrategyMapPage />} />

          {/* STRATEGY */}
          <Route path="strategy/company" element={<CompanyScorecards />} />
          <Route path="strategy/company/:fyid" element={<CompanyScorecard />} />
          <Route
            path="strategy/company/:fyid/:objectiveId"
            element={<CompanyScorecardObjective />}
          />

          <Route
            path="strategy/department"
            element={<DepartmentScorecards />}
          />
          <Route
            path="strategy/department/:fyid/:departmentId"
            element={<DepartmentScorecard />}
          />

          {/* SCORECARDS */}
          {/* Executive & MD don't have personal/individual scorecard */}
          <Route path="scorecards/my" element={<IndividualScorecard />} />
          <Route
            path="scorecards/my/:id"
            element={<IndividualScorecardDraftObjective />}
          />
          <Route
            path="scorecards/supervision"
            element={<EmployeeScorecard />}
          />
          <Route
            path="scorecards/supervision/:uid"
            element={<EmployeeScorecardView />}
          />
          <Route path="scorecards/people" element={<People />} />
          <Route path="scorecards/people/:uid" element={<PeopleView />} />
          <Route path="scorecards/reviews" element={<PerformanceReviews />} />

          {/* PORTFOLIO OF EVIDENCE */}
          <Route path="drive" element={<Drive />} />
          <Route path="drive/:id" element={<Drive />} />

          {/* project management */}
          <Route path="projects" element={<UserProjects />} />
          <Route path="projects/:id" element={<PortfolioProjects />} />
          <Route path="project/:projectId" element={<ProjectView />} />
          <Route path="portfolio" element={<PortfolioPage />} />
          <Route path="tasks" element={<Tasks />} />
          <Route path="checkin" element={<CheckIn />} />
          <Route path="checkin/:yearId" element={<CheckInYearView />} />
          <Route path="checkin/:yearId/:monthId" element={<CheckInMonthView />} />
          <Route path="checkin/:yearId/:monthId/:uid" element={<CheckInMonthUserView />} />
          <Route path="statistics" element={<Statistics />} />

          <Route path="*" element={<Navigate to="home/dashboard" />} />
        </Route>

        <Route path="/" element={<LandingPage />} />
        <Route path="/*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
};
const EMPLOYEE_USER_ROUTES = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="c" element={<PrivateLoggedIn />}>
          {/* OVERVIEW */}
          <Route path="home/dashboard" element={<Dashboard />} />
          <Route path="home/strategy-map" element={<StrategyMapPage />} />

          {/* STRATEGY */}
          <Route path="strategy/company" element={<CompanyScorecards />} />
          <Route path="strategy/company/:fyid" element={<CompanyScorecard />} />
          <Route
            path="strategy/company/:fyid/:objectiveId"
            element={<CompanyScorecardObjective />}
          />
          <Route
            path="strategy/department"
            element={<DepartmentScorecards />}
          />
          <Route
            path="strategy/department/:fyid/:departmentId"
            element={<DepartmentScorecard />}
          />
          <Route
            path="strategy/department/:fyid/:departmentId/:objectiveId"
            element={<DepartmentScorecardObjective />}
          />

          {/* SCORECARDS */}
          {/* Executive & MD don't have personal/individual scorecard */}
          <Route path="scorecards/my" element={<IndividualScorecard />} />
          <Route
            path="scorecards/my/:id"
            element={<IndividualScorecardDraftObjective />}
          />

          {/* PORTFOLIO OF EVIDENCE */}
          <Route path="drive" element={<Drive />} />
          <Route path="drive/:id" element={<Drive />} />

          {/* project management */}
          <Route path="projects" element={<UserProjects />} />
          <Route path="projects/:id" element={<PortfolioProjects />} />
          <Route path="project/:projectId" element={<ProjectView />} />
          <Route path="portfolio" element={<PortfolioPage />} />
          <Route path="tasks" element={<Tasks />} />
          <Route path="checkin" element={<CheckIn />} />
          <Route path="checkin/:yearId" element={<CheckInYearView />} />
          <Route path="checkin/:yearId/:monthId" element={<CheckInMonthView />} />
          <Route path="checkin/:yearId/:monthId/:uid" element={<CheckInMonthUserView />} />
          <Route path="statistics" element={<Statistics />} />

          <Route path="*" element={<Navigate to="home/dashboard" />} />
        </Route>

        <Route path="/" element={<LandingPage />} />
        <Route path="/*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
};
const GUEST_USER_ROUTES = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="c" element={<PrivateLoggedIn />}>
          {/* OVERVIEW */}
          <Route path="home/dashboard" element={<Dashboard />} />
          <Route path="home/strategy-map" element={<StrategyMapPage />} />

          <Route path="*" element={<Navigate to="home/dashboard" />} />
        </Route>

        <Route path="/" element={<LandingPage />} />
        <Route path="/*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
};

const MainRoutes = observer(() => {
  const { store } = useAppContext();
  const role = store.auth.role;

  useNetwork();

  const DEV_MODE = !process.env.NODE_ENV || process.env.NODE_ENV === "development";

  if (DEV_MODE) return <DEV_MODE_ROUTES />;

  switch (role) {
    case USER_ROLES.DIRECTOR_USER:
      return <DIRECTOR_USER_ROUTES />;

    case USER_ROLES.MD_USER:
      return <MD_USER_ROUTES />;

    case USER_ROLES.SUPER_USER:
      return <SUPER_USER_ROUTES />;

    case USER_ROLES.EXECUTIVE_USER:
      return <EXECUTIVE_USER_ROUTES />;

    case USER_ROLES.ADMIN_USER:
      return <ADMIN_USER_ROUTES />;

    case USER_ROLES.MANAGER_USER:
      return <MANAGER_USER_ROUTES />;

    case USER_ROLES.SUPERVISOR_USER:
      return <SUPERVISOR_USER_ROUTES />;

    case USER_ROLES.EMPLOYEE_USER:
      return <EMPLOYEE_USER_ROUTES />;

    default:
      return <GUEST_USER_ROUTES />;
  }
});

const App = () => {
  const store = new AppStore();
  const api = new AppApi(store);
  const ui = new UiStore();

  return (
    <div className="app">
      <AppContext.Provider value={{ store, api, ui }}>
        <MainRoutes />
        <SnackbarManager />
      </AppContext.Provider>
    </div>
  );
};

export default App;