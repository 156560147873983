import { observer } from "mobx-react-lite";
import {
  faChessKnight,
  faComments,
  faBriefcase,
  faLineChart,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation, Navigate } from "react-router-dom";
import { LoadingEllipsis } from "../../../shared/components/loading/Loading";
import { useAppContext } from "../../../shared/functions/Context";
import SectionHeader from "../components/SectionHeader";
import "./LandingPage.scss";

const style = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
};

const Loader = () => {
  return (
    <div style={style}>
      <LoadingEllipsis />
    </div>
  );
};

type ILocationState = {
  from: string;
};

interface INavProp {
  redirectSignIn: () => void;
}

const Navbar = (props: INavProp) => {
  const { redirectSignIn } = props;
  return (
    <div className="navbar-section uk-section-primary uk-preserve-color">
      <div data-uk-sticky="sel-target: .uk-navbar-container; cls-active: uk-navbar-sticky; cls-inactive: uk-navbar-transparent uk-light; top: 200">
        <nav className="uk-navbar-container">
          <div className="uk-container" data-uk-navbar>
            <div className="uk-navbar-left">
              <img
                className="brand"
                src={`${process.env.PUBLIC_URL}/logo512.png`}
                alt=""
              />
            </div>
            <div className="uk-navbar-right">
              <div>
                <ul className="uk-navbar-nav">
                  <li className="uk-active">
                    <a href="/#">Home</a>
                  </li>
                  <li>
                    <a
                      href="https://lotsinsights.com/contact"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Contact
                    </a>
                  </li>
                  <li className="uk-active">
                    <a href="#" onClick={redirectSignIn}>Login</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </div>
      <div className="header-section uk-section uk-light">
        <div className="uk-container">
          <div className="uk-grid-large uk-child-width-1-2@s" data-uk-grid>
            <div>
              <h1 className="header-section__title">
                Plan & Execute Your Company Strategy Effectively with a Digital
                Performance Management System.
              </h1>
              <p className="header-section__message">
                Digital PMS allow governing bodies to easily pull data and
                create accurate reports that can be dispensed to the public.
              </p>
              <div className="controls">
                <button
                  className="get-started kit-btn kit-btn-light uk-margin-right"
                  onClick={redirectSignIn}
                >
                  LOGIN
                </button>
              </div>
            </div>
            <div className="img-container">
              <img
                src={process.env.PUBLIC_URL + "images/dashboard1.png"}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Functionality = () => {
  return (
    <div className="functionality-section uk-section uk-section-default">
      <div className="uk-container">
        <SectionHeader
          subTitle="Key Functionality"
          title="Analyze effortlessly.
          Act intentionally"
          message="Drive performance and cross-functional collaboration with easy-to-use dashboards, data visualizations, and automated insights. Critical metrics are always up to date and available in real-time."
        />

        <div
          className="uk-grid-small uk-grid-match uk-child-width-1-2@s uk-child-width-1-4@m"
          data-uk-grid
        >
          <div>
            <div className="functionality-card kit-card kit-card-default">
              <div className="functionality-card__icon">
                <FontAwesomeIcon
                  icon={faLineChart}
                  style={{ color: "inherit" }}
                  size={"3x"}
                />
              </div>
              <h4 className="functionality-card__title">Reporting</h4>
            </div>
          </div>

          <div>
            <div className="functionality-card kit-card kit-card-default">
              <div className="functionality-card__icon">
                <FontAwesomeIcon
                  icon={faChessKnight}
                  style={{ color: "inherit" }}
                  size={"3x"}
                />
              </div>
              <h4 className="functionality-card__title">Strategizing</h4>
            </div>
          </div>

          <div>
            <div className="functionality-card kit-card kit-card-default">
              <div className="functionality-card__icon">
                <FontAwesomeIcon
                  icon={faComments}
                  style={{ color: "inherit" }}
                  size={"3x"}
                />
              </div>
              <h4 className="functionality-card__title">Feedback</h4>
            </div>
          </div>

          <div>
            <div className="functionality-card kit-card kit-card-default">
              <div className="functionality-card__icon">
                <FontAwesomeIcon
                  icon={faBriefcase}
                  style={{ color: "inherit" }}
                  size={"3x"}
                />
              </div>
              <h4 className="functionality-card__title">Administration</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Reporting = () => {
  return (
    <div className="center-snapshot-section uk-section uk-section-default">
      <div className="uk-container">
        <SectionHeader
          subTitle="Reporting"
          title="Analyze effortlessly.
          Act intentionally"
          message="A good performance management system will allow you to easily pull employee data, which you can use to guide your decision-making concerning promotions, training initiatives and the career progression of your employees."
        />

        <div className="snapshot uk-text-center">
          <img
            src="https://summitescrowandtitle.com/wp-content/uploads/2017/05/Customized-Information-Reporting-background.jpg"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

const Feedback = () => {
  return (
    <div className="right-snapshot-section uk-section uk-section-default">
      <div className="uk-container">
        <div className="uk-grid-match uk-child-width-1-2@s" data-uk-grid>
          <div>
            <SectionHeader
              subTitle="Feedback"
              title="Keep Employees Engaged."
              message="Performance management systems allow you to provide employees
              with well-informed feedback on their performance. This will help
              you and your employees identify areas of improvement and
              communicate what is expected of them."
            />
          </div>

          <div>
            <div className="snapshot uk-text-center">
              <img
                src="https://media.istockphoto.com/vectors/illustrations-flat-design-concept-video-conference-online-meeting-vector-id1226420814?k=20&m=1226420814&s=612x612&w=0&h=3XaFc8oYvViWIMUPD7XwFwvgxN9lVfjSQvP9m_z0J3c="
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Strategizing = () => {
  return (
    <div className="strategy left-snapshot-section uk-section uk-section-default">
      <div className="uk-container">
        <div className="uk-grid-match uk-child-width-1-2@s" data-uk-grid>
          <div>
            <div className="snapshot uk-text-center">
              <img
                src="https://www.seekpng.com/png/detail/265-2653788_forming-smart-strategies-transparent-background-chess-png.png"
                alt=""
              />
            </div>
          </div>

          <div>
            <SectionHeader
              subTitle="STRATEGIZING"
              title="Plan Strategically. Execute Effortlessly"
              message=" Data forms the bedrock from which all decisions are made. By
              making accurate data readily available to you, performance
              management systems allow you to design strategies and make
              decisions informed by reliable information. This data will allow
              you to monitor the health of your already existing strategies,
              and ensure that they are aligned with the overall goals of your
              organisation."
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const Administration = () => {
  return (
    <div className="center-snapshot-section uk-section uk-section-default">
      <div className="uk-container">
        <SectionHeader
          subTitle="Administration"
          title="Moving on from PaperWork."
          message="As performance management systems are digital solutions, they
          free you from having to rely on paper. Instead of sifting
          through hundreds if not thousands of forms, the system will
          easily provide the employee information that you require."
        />

        <div className="snapshot uk-text-center">
          <img src="https://wallpaperaccess.com/full/5137774.jpg" alt="" />
        </div>
      </div>
    </div>
  );
};

const Footer = () => {
  return (
    <div className="footer-section">
      <div className="uk-container">
        <div
          className="uk-grid-small uk-grid-match uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@m"
          data-uk-grid
        >
          <div>LOTS Website</div>
          <div>Live Support</div>
          <div>Innovation</div>
          <div>API Status</div>
        </div>
      </div>
    </div>
  );
};

const LandingPage = observer(() => {
  const { api, store } = useAppContext();
  const location = useLocation();

  const redirectSignIn = () => {
    api.auth.logInWithPopup();
  };

  if (store.auth.loading) return <Loader />;

  if (!store.auth.loading && store.auth.me) {
    const state = location.state as ILocationState;
    if (state) return <Navigate to={state.from} />;

    return <Navigate to="/c/home/dashboard" />;
  }

  return (
    <div>
      <Navbar redirectSignIn={redirectSignIn} />
      <Functionality />
      <Reporting />
      <Strategizing />
      <Feedback />
      <Administration />
      <Footer />
    </div>
  );
});

export default LandingPage;
